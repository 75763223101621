import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';
import { ProjectDefinitionSample, ProjectSamplingCadenceType, SamplingEvent, ProgressStatus, CMSHabitatAssayType } from 'src/shared/types';

import {
    CreateProjectDefinitionQueryResult,
    createProjectDefinitonQuery,
    ProjectDefinitionCompletionQueryResult,
    projectDefinitionCompletionQuery,
    getSubscriptionTypesQuery,
    GetSubscriptionTypeResult,
} from './queries/newProjectDefinitionQuery';

type HasProjectDefinitionQueryParams = {
    projectId: string;
};

type CreateProjectDefinitionParams = {
    customerId: string;
    projectName: string;
    projectId: string;
    projectCode: string;
    projectType: string;
    projectEndDate: string;
    subscriptionType: string;
    country: string;
    speciesOfInterest: string[];
    area: string;
    samplingCadenceType: ProjectSamplingCadenceType;
    samplingEvent: SamplingEvent[];
    projectLifespanYears: number;
    samplingEventsPerYear: number;
    estimatedSampleCountForTestTypes: {
        type: Pick<CMSHabitatAssayType, 'assayName' | 'habitatAssayKey' | 'habitatName'>;
        count: number;
    }[];
    initialSampleEstimate: number;
    habitatAssay: Pick<CMSHabitatAssayType, 'assayName' | 'habitatAssayKey' | 'habitatName'>[];
    projectLeadName: string;
    projectLeadEmail: string;
    projectLeadRole: string;
    sampleGroups: string[];
    targetOutcome: string;
    status: ProgressStatus;
    initialSampleEntries: ProjectDefinitionSample[];
};

const newProjectDefinitionGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        hasProjectDefinition: builder.query<ProjectDefinitionCompletionQueryResult, HasProjectDefinitionQueryParams>({
            query: params => ({
                body: projectDefinitionCompletionQuery,
                variables: params,
            }),
        }),
        getSubscriptionTypes: builder.query<GetSubscriptionTypeResult, null>({
            query: () => ({
                body: getSubscriptionTypesQuery,
            }),
        }),
        createProjectDefiniton: builder.mutation<CreateProjectDefinitionQueryResult, CreateProjectDefinitionParams>({
            query: params => ({
                body: createProjectDefinitonQuery,
                variables: { input: params },
            }),
        }),
    }),
});

export const {
    useCreateProjectDefinitonMutation,
    useHasProjectDefinitionQuery,
    useGetSubscriptionTypesQuery,
    useLazyHasProjectDefinitionQuery,
} = newProjectDefinitionGraphSlice;
