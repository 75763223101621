import * as Sentry from '@sentry/react';
import config from 'src/config';

interface ErrorServiceInterface {
    report: (error: Error) => void;
}

class ErrorService implements ErrorServiceInterface {
    private isReportingServiceInitialized = false;

    public constructor() {
        this.initializeReportingService();
    }

    public report(error: Error) {
        if (this.isReportingServiceInitialized) {
            Sentry.captureException(error);
        } else {
            console.log(error); // eslint-disable-line no-console
        }
    }

    public initializeReportingService() {
        if (this.isReportingServiceInitialized) {
            return;
        }
        if (config.shouldReportErrors) {
            Sentry.init({
                environment: config.deploymentEnv,
                dsn: 'https://87916080e3f845ab908b40fe182348e5@o4505425811406848.ingest.sentry.io/4505442338734080',
                ignoreErrors: ['AbortError'],
            });
            this.isReportingServiceInitialized = true;
        }
    }
}

export default new ErrorService();
