import React, { forwardRef, InputHTMLAttributes, ForwardedRef } from 'react';
import classNames from 'classnames';

import styles from './Input.module.scss';

export type Props = InputHTMLAttributes<HTMLInputElement> & {
    hasError?: boolean;
    hideMaxLengthLabel?: boolean;
    footerHint?: string;
};

const Input = (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
    const { className: classNameFromProps, hideMaxLengthLabel, hasError, footerHint, ...otherProps } = props;
    const className = classNames(styles.input, { [styles.hasError]: hasError }, classNameFromProps);

    return (
        <div>
            <input className={className} ref={ref} spellCheck={false} {...otherProps} />
            {!footerHint && otherProps.maxLength && !hideMaxLengthLabel && (
                <div className={styles.limit}>Maximum {otherProps.maxLength} characters</div>
            )}
            {footerHint && <div className={styles.limit}>{footerHint}</div>}
        </div>
    );
};

export default forwardRef(Input);
